<template src="./index.html" />

<script>
import * as XLSX from 'xlsx'
import {
  BBreadcrumb,
  BButton,
  BFormFile,
  BCard,
  BCol,
  BContainer,
  BFormSelect,
  BOverlay,
  BPagination,
  BRow,
  BFormCheckbox,
  BFormRadio,
  BFormGroup,
  VBTooltip,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import { mapActions, mapGetters } from 'vuex'
import { getUser } from '@/auth/utils'
import { hasPermissionForResource } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { ApiCode } from '@/const/api'

export default {
  name: 'ApplicantStudent',
  directives: {
    Ripple,
  },
  components: {
    BBreadcrumb,
    BButton,
    BFormFile,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BFormGroup,
    BRow,
    XLSX,
    VueGoodTable,
    BFormCheckbox,
    BFormRadio,
    vSelect,
    ToastificationContent,
  },
  props: {

  },
  data() {
    return {
      user: getUser(),
      table: {
        fields: [
          // {
          //   field: 'stt', label: 'STT', sortable: false, type: 'string',
          // },
          {
            field: 'identification', label: 'Số CCCD(*)', sortable: false, type: 'string',
          },
          {
            field: 'name', label: 'Họ tên(*)', sortable: false, type: 'string',
          },
          {
            field: 'dob', label: 'Ngày sinh(*)', sortable: false, type: 'string',
          },
          {
            field: 'gender', label: 'Giới tính(*)', sortable: false, type: 'int',
          },
          {
            field: 'phone', label: 'Số điện thoại', sortable: false, type: 'string',
          },
          {
            field: 'ethnic', label: 'Dân tộc', sortable: false, type: 'string',
          },
          {
            field: 'province', label: 'Quê quán (tỉnh)', sortable: false, type: 'string',
          },
          {
            field: 'generalEducationLevelCode', label: 'Trình độ GDPT(*)', sortable: false, type: 'string',
          },
          {
            field: 'professionalQualificationCode', label: 'Trình độ CM(*)', sortable: false, type: 'string',
          },
          {
            field: 'joinPartyYear', label: 'Năm vào Đảng', sortable: false, type: 'int',
          },
          {
            field: 'currentPosition', label: 'Chức vụ hiện tại(*)', sortable: false, type: 'string',
          },
          {
            field: 'planningPosition', label: 'Chức vụ quy hoạch', sortable: false, type: 'string',
          },
          {
            field: 'workingRank', label: 'Năm được giữ ngạch chuyên viên', sortable: false, type: 'string',
          },
          {
            field: 'workingUnit', label: 'Cơ quan đơn vị công tác(*)', sortable: false, type: 'string',
          },
          {
            field: 'agencyLevelCode', label: 'Cấp cơ quan(*)', sortable: false, type: 'string',
          },
          {
            field: 'workingOrganization', label: 'Cơ quan chủ quản cơ quan, đơn vị công tác', sortable: false, type: 'string',
          },
          {
            field: 'applicantTypeCode', label: 'Đối tượng(*)', sortable: false, type: 'string',
          },
          {
            field: 'isLeader', label: 'Là lãnh đạo', sortable: false, type: 'string',
          },
          {
            field: 'isCentralLevel', label: 'Cơ quan thuộc ngành dọc trung ương', sortable: false, type: 'string',
          },
          {
            field: 'note', label: 'Ghi chú', sortable: false, type: 'string',
          },
        ],
        items: [],
      },
      tenFile: '',
      dataExcel: [],
      totalRecords: 0,
      serverParams: {
        page: 1,
        perPage: 10,
      },
      loading: false,
      selected: '',
      numberConfig: 1,
      distinctClassCode: [],
      admissionRoundId: null,
      valueSetup: 0,
      checkAdmissionRound: false,
    }
  },
  computed: {
    ...mapGetters({
      total: 'rooms/total',
      dataExport: 'importAdmission/dataExport',
      admissionRounds: 'admissionRound/admissionRoundsByOrganization',
    }),
    creatable() {
      return hasPermissionForResource(PermissionCode.CREATE, ResourceCode.IMPORT_ADMISSION_APPLICANT_STUDENT)
    },
  },
  async created() {
    await this.getAdmissionRoundsByOrganization({ organizationId: this.user.orgId })
  },
  methods: {
    ...mapActions({
      importStudents: 'importAdmission/importStudents',
      getDataExport: 'importAdmission/downloadTemplateImportAdmission',
      getAdmissionRoundsByOrganization: 'admissionRound/readAdmissionRoundsByOrganization',
    }),
    async importExcel(e) {
      const { files } = e.target
      if (!files.length) {
        return
      } if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        // show err
      }
      if (files[0].name) {
        this.tenFile = files[0].name
      }
      this.readFile(files)
    },
    readFile(files) {
      const fileReader = new FileReader()
      fileReader.onload = async ev => {
        try {
          const data = ev.target.result
          const workbook = XLSX.read(data, {
            type: 'binary',
          })
          const wsName = workbook.SheetNames[0]
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsName], {
            header: 1, // Sử dụng 1 để lấy dữ liệu dưới dạng mảng các mảng
            defval: '',
          })
          if (ws.length > 0) {
            // Lấy dữ liệu từ hàng 11 trở đi (bỏ qua tiêu đề và 10 hàng đầu tiên)
            this.excelList = ws.slice(11)
          } else {
            this.showToast('File không có dữ liệu', 'XCircleIcon', 'danger', '')
            return
          }
          this.dataExcel = []
          // Lặp qua từng hàng trong excelList và tạo đối tượng
          for (let k = 0; k < this.excelList.length; k++) {
            const row = this.excelList[k]
            if (row[1] == null || row[1] === '') {
              break
            } else {
              const obj = {
                admissionRoundId: this.admissionRoundId,
                identification: row[1],
                name: row[2],
                gender: row[3] == null ? 'Nam' : 'Nữ',
                dob: row[3] == null ? row[4] : row[3],
                ethnic: row[5],
                province: row[6],
                phone: row[7],
                generalEducationLevelCode: row[8],
                professionalQualificationCode: row[9],
                joinPartyYear: row[10],
                currentPosition: row[11],
                planningPosition: row[12],
                workingRank: row[13],
                workingUnit: row[14],
                agencyLevelCode: row[15],
                workingOrganization: row[16],
                applicantTypeCode: row[17],
                isLeader: row[18] == null ? ' ' : 'x',
                isCentralLevel: row[19] == null ? ' ' : 'x',
                note: row[20],
              }
              // Kiểm tra trùng lặp dựa trên giá trị của cột identification
              if (this.dataExcel.filter(e => e.identification === obj.identification).length > 0) {
                this.showToast('Trùng CCCD!', 'XCircleIcon', 'danger', obj.identification.toString())
                return
              }

              // Thêm đối tượng obj vào danh sách dataExcel
              this.dataExcel.push(obj)
            }
          }
          // Cập nhật bảng và tổng số bản ghi
          this.table.items = this.dataExcel
          this.totalRecords = this.dataExcel.length
          if (this.totalRecords > 10) {
            this.table.items = this.dataExcel.slice(0, 10)
          }
        } catch (e) {
          alert(`Read failure! ${e}`)
        }
      }
      fileReader.readAsBinaryString(files[0])
    },

    resetInputFile() {
      this.$refs.inputFileRef.reset()
    },
    async importAdmission() {
      this.loading = true
      if (this.admissionRoundId === null) {
        this.showToast('Đợt xét tuyển không được bỏ trống', 'XCircleIcon', 'warning')
        return
      }
      this.loading = true
      try {
        const data = this.dataExcel
        const res = await this.importStudents(data)
        if (res.code === ApiCode.SUCCESS) {
          this.showToast('Lưu thành công', 'CheckIcon', 'success', '')
          this.resetInputFile()
        } else {
          this.showToast(res.message, 'XCircleIcon', 'danger', '')
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.loading = false
      }
    },
    onPageChange(params) {
      const start = params.currentPerPage * (params.currentPage - 1)
      const end = this.dataExcel.length > (params.currentPerPage * params.currentPage) ? (params.currentPerPage * params.currentPage) : this.dataExcel.length
      this.table.items = this.dataExcel.slice(start, end)
    },

    onPerPageChange(params) {
      const start = params.currentPerPage * (params.currentPage - 1)
      const end = this.dataExcel.length > (params.currentPerPage * params.currentPage) ? (params.currentPerPage * params.currentPage) : this.dataExcel.length
      this.table.items = this.dataExcel.slice(start, end)
    },
    async downloadFile() {
      await this.getDataExport()
      const a = document.createElement('a')
      document.body.appendChild(a)
      const blob = new Blob([this.dataExport.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      const url = window.URL.createObjectURL(blob)
      a.href = url
      a.download = 'file_mau_ds_du_tuyen.xlsx'
      a.click()
      window.URL.revokeObjectURL(url)
    },
    showToast(title, icon, variant, text) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
          text,
        },
      })
    },
  },
}
</script>

<style type="text/css">
.form_file{
  width: auto !important;
}
</style>
